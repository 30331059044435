/** @format */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Paper, Typography } from '@mui/material';

const styles = {
  inactivityModal: {
    width: '600px',
    margin: '0px auto',
    top: '50%',
    position: 'relative',
    transform: 'translateY(-50%)',
    outline: 0,
  },
};

const InactivityModal = ({ open, logoutTime }) => {
  const [internalTimer, setInternalTimer] = useState(logoutTime);

  useEffect(() => {
    let interval = null;

    if (open) {
      interval = setInterval(() => {
        setInternalTimer((prev) => prev - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
      setInternalTimer(logoutTime);
    };
  }, [open, logoutTime]);

  if (open && internalTimer <= 0) {
    window.location.reload();
  }

  return (
    <Modal open={open}>
      <Paper sx={styles.inactivityModal}>
        <Typography variant='h4' sx={{ textAlign: 'center' }} p={5} pb={0}>
          Are you still there?
        </Typography>

        <Typography variant='body1' textAlign='center'>
          Please interact with the website, otherwise you will be logged out.
        </Typography>

        <Typography variant='h6' p={5} textAlign='center'>
          {/* change text one second before logging out */}
          {internalTimer > 1 ? `logging out in ${internalTimer} seconds` : 'Log out in progress'}
        </Typography>
      </Paper>
    </Modal>
  );
};

InactivityModal.propTypes = {
  open: PropTypes.bool,
  logoutTime: PropTypes.number,
};

InactivityModal.defaultProps = {
  open: true,
  logoutTime: 60,
};

export default InactivityModal;
