/** @format */

import React from 'react';
import { GridOverlay } from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
});

const LoadingOverlay = () => {
  const classes = useStyles();

  return (
    <GridOverlay>
      <LinearProgress className={classes.root} />
    </GridOverlay>
  );
};

export default LoadingOverlay;
