/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Grid, Paper, Skeleton, Typography, useMediaQuery } from '@mui/material';

import { getFullAddress } from 'utils';

import useStyles from './styles';

const ViolationAgencyCard = ({
  agency: { name = '', streetAddress = '', city = '', state = '', zipCode = '', logoLink = '' },
}) => {
  const minWidthReached = useMediaQuery('(min-width:480px)');
  const classes = useStyles({ minWidthReached });
  const fullAddress = getFullAddress({ street: streetAddress, city, state, zipCode });

  return (
    <Paper className={classes.root} elevation={3}>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Typography variant='h6'>Violation Issued By</Typography>
        </Grid>
        <Grid container item spacing={2} alignItems='center'>
          <Grid item xs='auto'>
            <Avatar className={classes.avatar} alt={name} src={logoLink} variant={logoLink ? 'square' : 'rounded'} />
          </Grid>
          <Grid container item direction='column' spacing={1} xs>
            <Grid item>
              {name ? (
                <Typography variant={minWidthReached ? 'h3' : 'h4'}>{name}</Typography>
              ) : (
                <Skeleton animation='wave' variant='rectangle' height={30} width='80%' />
              )}
            </Grid>
            <Grid item>
              {fullAddress ? (
                <Typography>{fullAddress}</Typography>
              ) : (
                <Skeleton animation='wave' variant='rectangle' height={21} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

ViolationAgencyCard.propTypes = {
  agency: PropTypes.shape({
    name: PropTypes.string,
    streetAddress: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    src: PropTypes.string,
    logoLink: PropTypes.string,
  }),
};

ViolationAgencyCard.defaultProps = { agency: {} };

export default ViolationAgencyCard;
