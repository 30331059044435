/** @format */

import merge from 'deepmerge';
import { grey } from '@mui/material/colors';

import { THEMES } from 'utils/constants';

import bli from './bli';

const customBlue = {
  50: '#e9f0fb',
  100: '#c8daf4',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2 ',
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: bli.palette.light,
  components: {},
  header: {
    color: grey[500],
    background: '#FFF',
    search: { color: grey[800] },
    indicator: { background: customBlue[600] },
  },
  footer: { color: grey[500], background: '#FFF' },
};

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: bli.palette.dark,
  components: {},
  header: { color: grey[300], background: '#1B2635', search: { color: grey[200] } },
  footer: { color: grey[300], background: '#233044' },
});

const variants = [defaultVariant, darkVariant];

export default variants;
