/** @format */

import { makeStyles } from '@mui/styles';

export const useVideoModalStyles = makeStyles({
  root: {
    minWidth: 280,
    width: '80vw',
    maxWidth: 950,
    maxHeight: '80vh',
    overflowY: 'auto',
  },
});
