/** @format */

import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  root: { padding: ({ minWidthReached }) => theme.spacing(minWidthReached ? 4 : 2) },
  ledgerHeader: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
  },
  alert: { filter: 'unset' },
}));

export const useLedgerListStyles = makeStyles((theme) => ({
  list: {
    maxHeight: 392,
    overflowY: 'auto',
    padding: theme.spacing(0, 1, 0, 0),
    marginTop: 0,
  },
  ledger: {
    borderRadius: 6,
    height: 72,
    marginBottom: theme.spacing(1),
    '&:last-child': { marginBottom: 0 },
  },
  positive: {
    background: theme.palette.ledger.negative.background,
    border: `1px solid ${theme.palette.ledger.negative.border}`,
  },
  negative: {
    background: theme.palette.ledger.positive.background,
    border: `1px solid ${theme.palette.ledger.positive.border}`,
  },
  pending: {
    background: theme.palette.ledger.positive.background,
    border: `1px dashed ${theme.palette.warning.border}`,
  },
  payment: { width: 240 },
  divider: { borderStyle: 'dashed' },
}));

export const usePaymentModalStyles = makeStyles({
  root: {
    width: 800,
    maxWidth: '88vw',
    maxHeight: '88vh',
    overflowY: 'auto',
  },
  button: { width: 200 },
});
