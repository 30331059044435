/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  labelContainer: { flexGrow: 1, padding: theme.spacing(0, 2) },
  separatorContainer: { flexGrow: 100 },
  separator: { borderRadius: 2, height: 4, width: '100%' },
  valueContainer: { flexGrow: 1, textAlign: 'right', padding: theme.spacing(0, 2) },
}));

const LabelValue = ({ label, value, lineColor, sxLabel, sxValue }) => {
  const classes = useStyles();

  return (
    <Grid container item alignItems='center' justifyContent='space-between'>
      <Grid item className={classes.labelContainer}>
        <Typography variant='body1' sx={sxLabel}>
          {label}
        </Typography>
      </Grid>
      <Grid item className={classes.separatorContainer}>
        <LinearProgress color={lineColor} className={classes.separator} value={100} variant='determinate' />
      </Grid>
      <Grid item className={classes.valueContainer}>
        <Typography variant='body1' sx={sxValue}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

LabelValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element]),
  lineColor: PropTypes.string,
  sxLabel: PropTypes.shape({}),
  sxValue: PropTypes.shape({}),
};

LabelValue.defaultProps = {
  label: '[label placeholder]',
  value: '[value placeholder]',
  lineColor: 'primary',
  sxLabel: {},
  sxValue: {},
};

export default LabelValue;
