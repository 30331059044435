/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
}));

const DataGridFooter = ({ label }) => {
  const classes = useStyles();

  return <div className={classes.root}>{label}</div>;
};

DataGridFooter.propTypes = {
  label: PropTypes.string,
};

DataGridFooter.defaultProps = {
  label: '',
};

export default DataGridFooter;
