/** @format */

import React from 'react';
import PropTypes from 'prop-types';

import Map from './Map';
import Marker from './Marker';

const GoogleMap = ({ latLng, map, mapRef }) => (
  <Map mapRef={mapRef} map={map}>
    <Marker position={latLng} />
  </Map>
);

GoogleMap.propTypes = {
  latLng: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  map: PropTypes.shape({}),
  mapRef: PropTypes.shape({}),
};

GoogleMap.defaultProps = {
  latLng: null,
  map: null,
  mapRef: null,
};

export default GoogleMap;
