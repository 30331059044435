/** @format */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, Alert } from '@mui/material';

import { clearSnackbar, resetSnackbar } from 'reducers/snackbarSlice';

const GlobalSnackbar = () => {
  const { open, message, severity, anchor, duration } = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(clearSnackbar());
  const handleExited = () => dispatch(resetSnackbar());

  return (
    <Snackbar
      key={message}
      anchorOrigin={anchor}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleExited,
      }}>
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalSnackbar;
