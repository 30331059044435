/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  root: {
    '& fieldset': { borderColor: theme.palette.ui.input },
    '& .MuiInputAdornment-root': { cursor: 'pointer', width: theme.spacing(6) },
    '& .MuiOutlinedInput-adornedEnd': { paddingRight: 'unset' },
  },
}));

const SearchBar = ({ clearSearch, label, margin, onChange, placeholder, size, value, variant, ...rest }) => {
  const classes = useStyles();

  return (
    <TextField
      id={label.replaceAll(' ', '-')}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>{value ? <CloseIcon onClick={clearSearch} /> : <SearchIcon />}</InputAdornment>
        ),
      }}
      className={classes.root}
      label={label}
      margin={margin}
      onChange={onChange}
      placeholder={placeholder}
      size={size}
      value={value}
      variant={variant}
      {...rest}
    />
  );
};

SearchBar.propTypes = {
  clearSearch: PropTypes.func,
  label: PropTypes.string,
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  value: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

SearchBar.defaultProps = {
  clearSearch: () => {},
  label: 'Search',
  margin: 'normal',
  onChange: () => {},
  placeholder: 'Search...',
  size: 'small',
  value: '',
  variant: 'outlined',
};

export default SearchBar;
