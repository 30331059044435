/** @format */

import React, { useRef, useState, useEffect } from 'react';
import { render } from 'react-dom';
import { useSelector } from 'react-redux';
import { Alert } from '@mui/material';

import darkModeStyles from './darkModeStyles';

// MOCK_CAMERA_COORDS is used until all cameras' coordinates have been set
const MOCK_CAMERA_COORDS = { lat: 35.07180647873182, lng: -85.31766650205354 };

const useMap = (coords, enableMapClick) => {
  const theme = useSelector((state) => state.theme);
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [latLng, setLatLng] = useState(
    coords ? { lat: coords.lat, lng: coords.long || coords.lng } : MOCK_CAMERA_COORDS,
  );
  const [placesService, setPlacesService] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [inputPlace, setInputPlace] = useState('');
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);

  const onMapClick = (e) => {
    setLatLng(e.latLng.toJSON());
    setInputPlace('');
    setSelectedPlace(null);
  };

  useEffect(() => {
    if (mapRef.current && !map) {
      setMap(
        new window.google.maps.Map(mapRef.current, {
          center: latLng,
          zoom: 14,
          styles: theme.darkMode && darkModeStyles,
        }),
      );
    }
  }, [mapRef, map, latLng, theme.darkMode]);

  useEffect(() => {
    const googleMap = document.getElementById('google-map');
    if (!latLng.lat) {
      if (!document.getElementById('google-map-alert-overlay')) {
        const alertDiv = document.createElement('div');
        alertDiv.setAttribute('id', 'google-map-alert-overlay');
        render(
          <Alert severity='warning' sx={{ position: 'relative', width: 'fit-content', margin: 'auto', top: 10 }}>
            GPS coordinates are unavailable for this event!
          </Alert>,
          alertDiv,
        );
        googleMap.appendChild(alertDiv);
      }
    } else {
      const alertDiv = document.getElementById('google-map-alert-overlay');
      if (alertDiv) googleMap.removeChild(alertDiv);
    }
  }, [latLng]);

  useEffect(() => {
    if (map && enableMapClick) {
      map.addListener('click', onMapClick);
      setPlacesService(new window.google.maps.places.PlacesService(map));
    }
  }, [map, enableMapClick]);

  useEffect(() => {
    if (placesService && inputPlace) {
      setLoading(true);
      placesService.textSearch(
        { location: MOCK_CAMERA_COORDS, radius: '10000', query: inputPlace },
        (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setPlaces(results);
            setLoading(false);
          }
        },
      );
    }
  }, [inputPlace, placesService]);

  useEffect(() => {
    if (selectedPlace) {
      setLatLng({ lat: selectedPlace.geometry.location.lat(), lng: selectedPlace.geometry.location.lng() });
      map.setCenter(selectedPlace.geometry.location);
    }
  }, [selectedPlace, map]);

  return {
    mapRef,
    map,
    latLng,
    setLatLng,
    selectedPlace,
    setSelectedPlace,
    inputPlace,
    setInputPlace,
    places,
    loading,
  };
};

export default useMap;
