/** @format */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Modal, Grid, IconButton } from '@mui/material';
import { Clear, ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import clsx from 'clsx';

import useStyles from './styles';

const xsMapping = { 0: 'auto', 1: 12, 2: 6, 3: 4, 4: 3, 6: 2, 12: 1 };

const Carousel = ({ children, cols }) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const maxIndex = children?.length ? children.length - 1 : 0;

  const handleOpen = (e) => {
    setSelectedImageIndex(Number(e.target.id));
    setModalOpen(true);
  };

  const handleClose = () => setModalOpen(false);

  const handleChangeImage = (delta) => {
    setSelectedImageIndex((prevIndex) => prevIndex + delta);
  };

  const handleKeyDown = useCallback(
    (e) => {
      switch (e.keyCode) {
        case 13:
        case 27:
        case 32:
          e.preventDefault();
          handleClose();
          break;
        case 37:
          if (selectedImageIndex > 0) handleChangeImage(-1);
          break;
        case 39:
          if (selectedImageIndex < maxIndex) handleChangeImage(1);
          break;
        default:
          break;
      }
    },
    [maxIndex, selectedImageIndex],
  );

  useEffect(() => {
    if (modalOpen) window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [modalOpen, handleKeyDown]);

  return (
    children && (
      <>
        <Modal open={modalOpen} BackdropProps={{ className: classes.backdrop }}>
          <Grid container justifyContent='space-between' alignItems='center' className={classes.carousel}>
            <IconButton onClick={handleClose} className={clsx(classes.iconBtn, classes.clearBtn)}>
              <Clear className={classes.iconSvg} />
            </IconButton>
            <Grid item>
              <IconButton
                disabled={selectedImageIndex === 0}
                onClick={() => handleChangeImage(-1)}
                className={classes.iconBtn}>
                <ArrowBackIosNew className={classes.iconSvg} />
              </IconButton>
            </Grid>
            <Grid item>
              <img
                src={children[selectedImageIndex]?.props.src}
                alt={children[selectedImageIndex]?.props.alt}
                className={classes.modalImg}
              />
            </Grid>
            <Grid item>
              <IconButton
                disabled={selectedImageIndex === maxIndex}
                onClick={() => handleChangeImage(1)}
                className={classes.iconBtn}>
                <ArrowForwardIos className={classes.iconSvg} />
              </IconButton>
            </Grid>
          </Grid>
        </Modal>
        {children.map((child) => (
          <Grid item key={child.key} xs={xsMapping[cols]}>
            <ButtonBase onClick={handleOpen}>{child}</ButtonBase>
          </Grid>
        ))}
      </>
    )
  );
};

Carousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  cols: PropTypes.number,
};

Carousel.defaultProps = {
  children: null,
  cols: 0,
};

export default Carousel;
