/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Modal, Grid, LinearProgress, Typography } from '@mui/material';
import style from './style';

const AuthModal = ({ loading, children }) => (
  <div>
    <Modal open disableAutoFocus>
      <Box sx={style}>
        {loading && <LinearProgress sx={{ borderRadius: '4px 4px 0 0' }} />}
        <Grid container direction='column' spacing={1} p={2}>
          <Grid item>
            <Box pt={3}>
              <img src='/ng_logo_dark.svg' style={{ width: '55px', margin: '0px auto' }} alt='New Guard Logo' />
            </Box>
          </Grid>

          <Grid item>
            <Box mt={2} mb={3}>
              <Typography variant='h5'>{children}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  </div>
);

AuthModal.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

AuthModal.defaultProps = { loading: false };

export default AuthModal;
