/** @format */

export const agencyMock = {
  // rejectedEvents
  get: [],
  post: [],
  put: [],
};

export const eventMediaMock = {
  get: [],
  put: [],
  post: [],
};

export const internalReviewMock = {
  get: [],
  post: [],
  put: [],
};

export const agencyReviewMock = {
  get: [],
  post: [],
  put: [],
};

export const cameraMock = {
  get: [],
  post: [],
  put: [],
};

export const bankingMock = {
  get: [],
  post: [],
  put: [],
};

export const appConfigMock = {
  get: [],
  post: [],
  put: [],
  delete: [],
};

export const agencyDashboardMock = {
  get: [],
};

export const userMock = {
  get: [],
  post: [],
  put: [],
};

export const preApprovalMock = {
  get: [],
  post: [],
};

export const violationMock = {
  get: [],
  post: [],
};

export const reportTemplateMock = {
  get: [],
};

export const commentMock = {
  get: [],
  post: [],
};

export const maypopMock = {
  get: [],
};

export const consequenceMock = {
  get: [],
  post: [],
};

export const printerManagerMock = {
  get: [],
  post: [],
  put: [],
};

export const paymentService = {
  get: [],
  post: [],
  put: [],
};
