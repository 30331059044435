/** @format */

import { DEFAULT_ANCHOR_ORIGIN } from './constants';

export const formatDate = (date = null, options = null, locales = 'en-US') => {
  const dateObj = new Date(date);
  const isDateValid = !Number.isNaN(dateObj.getTime());
  // const locales = 'en-US';
  const defaultOptions = { month: 'short', day: '2-digit', year: 'numeric' };

  return {
    obj: dateObj,
    string: isDateValid
      ? new Intl.DateTimeFormat(locales, options || defaultOptions).format(dateObj)
      : dateObj.toString(),
    valid: isDateValid,
    value: isDateValid ? dateObj.getTime() : 0,
  };
};

export const handleShowSnackBar = (result, enqueueSnackbar) => {
  if (result.type === 'success') {
    enqueueSnackbar(result.msg, { variant: 'success', anchorOrigin: DEFAULT_ANCHOR_ORIGIN });
  } else {
    enqueueSnackbar(result.msg, { variant: 'error', anchorOrigin: DEFAULT_ANCHOR_ORIGIN });
  }
};

export const getFullName = ({ first = '', middle = '', last = '' }) =>
  first || middle || last ? `${first} ${middle} ${last}` : '-';

export const getFullAddress = ({ street = '', city = '', state = '', zipCode = '' }) => {
  let address = '';
  if (street) address += `${street}, `;
  if (city) address += `${city}, `;
  if (state) address += `${state}, `;
  if (zipCode) address += `${zipCode}`;

  return address;
};

export const getErrMsg = (err) => {
  if (typeof err.response?.data === 'string') return err.response?.data;
  return err?.response?.data?.message || err?.message;
};
