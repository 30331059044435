/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Divider, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { MonetizationOn, Payment } from '@mui/icons-material';
import clsx from 'clsx';

import { formatDate } from 'utils';

import { useLedgerListStyles } from './styles';

const LedgerList = ({ ledgers, amountOwed, webOption }) => {
  const classes = useLedgerListStyles();
  const ccFee = webOption?.ccFee || 0;
  let totalDue = 0;

  return (
    <Stack spacing={2}>
      <List className={classes.list}>
        {ledgers.length <= 0
          ? 'No payment items have been recorded.'
          : ledgers.map((ledger) => {
              const positive = ledger?.amount >= 0;
              totalDue += Number(ledger?.amount);
              const pending = !!ledger?.pending;

              return (
                <>
                  {pending ? (
                    <ListItem key={ledger.id} className={clsx(classes.ledger, classes.pending)}>
                      <ListItemText
                        primary={<Typography fontWeight={800}>{ledger?.action?.type?.description}</Typography>}
                        secondary='Pending Transaction'
                      />

                      <ListItemText
                        sx={{ textAlign: 'right' }}
                        primary={
                          <Stack direction='row' alignItems='center' justifyContent='flex-end' spacing={1}>
                            <Payment fontSize='small' color='warning' />
                            <Typography fontWeight={800}>{ledger?.amount}</Typography>
                          </Stack>
                        }
                        secondary={formatDate(ledger?.dateTime).string}
                      />
                    </ListItem>
                  ) : (
                    <ListItem
                      key={ledger.id}
                      className={clsx(classes.ledger, classes[positive ? 'positive' : 'negative'])}>
                      <ListItemText
                        primary={<Typography fontWeight={800}>{ledger?.action?.type?.description}</Typography>}
                      />

                      <ListItemText
                        sx={{ textAlign: 'right' }}
                        primary={
                          <Stack direction='row' alignItems='center' justifyContent='flex-end' spacing={1}>
                            <MonetizationOn fontSize='small' color={positive ? 'error' : 'success'} />
                            <Typography fontWeight={800}>{ledger?.amount}</Typography>
                          </Stack>
                        }
                        secondary={formatDate(ledger?.dateTime).string}
                      />
                    </ListItem>
                  )}
                </>
              );
            })}
      </List>

      {totalDue >= 0.005 ? (
        <Stack alignItems='flex-end'>
          <Stack direction='row' justifyContent='space-between' alignItems='flex-end' className={classes.payment}>
            <Typography>SUBTOTAL</Typography>
            <Typography>${amountOwed}</Typography>
          </Stack>
          <Stack direction='row' justifyContent='space-between' spacing={1} my={1} className={classes.payment}>
            <Typography>Credit Card Fee</Typography>
            <Typography>${ccFee}</Typography>
          </Stack>

          <Divider className={clsx(classes.payment, classes.divider)} />

          <Stack direction='row' justifyContent='space-between' spacing={1} mt={1} className={classes.payment}>
            <Typography variant='h6'>TOTAL DUE</Typography>
            <Typography fontWeight={800} variant='h6' color='error'>
              ${amountOwed + ccFee}
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <Typography textAlign='right' variant='h6'>
          THE BALANCE IS $0.00. NO PAYMENT DUE
        </Typography>
      )}
    </Stack>
  );
};

LedgerList.propTypes = {
  ledgers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      action: PropTypes.shape({ type: PropTypes.shape({ description: PropTypes.string }) }),
      amount: PropTypes.number,
      user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      dateTime: PropTypes.string,
    }),
  ),
  amountOwed: PropTypes.number,
  webOption: PropTypes.shape({ ccFee: PropTypes.number }),
};

LedgerList.defaultProps = {
  ledgers: [],
  amountOwed: 0,
  webOption: {},
};

export default LedgerList;
