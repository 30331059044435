/** @format */

import React, { Suspense, memo, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Layout from 'layouts';

const Page404 = lazy(() => import('pages/auth/Page404'));
const ViolatorPage = lazy(() => import('pages/ViolationPayment'));

const Routes = () => (
  <BrowserRouter>
    <Suspense fallback={<div className='loading-app' />}>
      <Switch>
        <Route exact path='/'>
          <Layout>
            <ViolatorPage />
          </Layout>
        </Route>
        <Route path='*' component={() => <Page404 />} />
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default memo(Routes);
