/** @format */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, Grid, Typography, Box, TextField } from '@mui/material';
import { AddRoad, CorporateFare, LocationOn, Person } from '@mui/icons-material';

import { GoogleMap } from 'components';
import { useMap } from 'hooks';

import { useViolationLocationStyles } from './styles';

const ViolationLocation = ({ gps, cameraData }) => {
  const { mapRef, map, latLng, setLatLng } = useMap(gps);
  const classes = useViolationLocationStyles();

  useEffect(() => {
    setLatLng(gps);
    map?.setCenter(gps);
  }, [gps, map, setLatLng]);

  return (
    <Card className={classes.cardContainer}>
      <CardMedia className={classes.mapContainer}>
        <GoogleMap mapRef={mapRef} map={map} latLng={latLng} />
      </CardMedia>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant='h6'>Location Info</Typography>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Person sx={{ mr: 1, my: 0.5 }} />
                <TextField fullWidth label='Agency' value={cameraData?.agency} variant='standard' disabled />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <LocationOn sx={{ mr: 1, my: 0.5 }} />
                <TextField fullWidth label='Location' value={cameraData?.location} variant='standard' disabled />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <CorporateFare sx={{ mr: 1, my: 0.5 }} />
                <TextField
                  fullWidth
                  label='Enforcement Site'
                  value={cameraData?.enforcementSite}
                  variant='standard'
                  disabled
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <AddRoad sx={{ mr: 1, my: 0.5 }} />
                <TextField fullWidth label='Lane Group' value={cameraData?.laneGroup} variant='standard' disabled />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ViolationLocation.propTypes = {
  gps: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  cameraData: PropTypes.shape({}),
};

ViolationLocation.defaultProps = {
  gps: null,
  cameraData: null,
};

export default ViolationLocation;
