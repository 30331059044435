/** @format */

import { adaptV4Theme, createTheme as createMuiTheme } from '@mui/material/styles';
import variants from './variants';
import typography from './typography';
import overrides from './overrides';
import breakpoints from './breakpoints';
import props from './props';

const createTheme = (name) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  return createMuiTheme(
    adaptV4Theme({
      spacing: 8,
      breakpoints,
      overrides: overrides(themeConfig.name),
      props,
      typography,
      palette: themeConfig.palette,
    }),
    {
      name: themeConfig.name,
      components: themeConfig.components,
      header: themeConfig.header,
      footer: themeConfig.footer,
    },
  );
};

export default createTheme;
