/** @format */

import { useEffect, useState } from 'react';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';

import paymentService from 'services/paymentService';
import { getPublicCredentials } from 'utils/localStorage';
import { handleShowSnackBar, getErrMsg } from 'utils';

const usePayments = () => {
  const accessToken = get(useReactOidc(), 'oidcUser.access_token', '');
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState({ fetchCCTypes: false, processPayment: false });
  const [ccTypes, setCCTypes] = useState([]);

  const publicCredentails = getPublicCredentials();

  const payByNewguardCC = async (formData) => {
    setLoading((prev) => ({ ...prev, processPayment: true }));
    try {
      const result = await paymentService.post.publicNewguardCC(publicCredentails, formData);
      setLoading((prev) => ({ ...prev, processPayment: false }));
      handleShowSnackBar({ msg: 'Payment by New Guard Credit Card accepted!', type: 'success' }, enqueueSnackbar);

      return result;
    } catch (err) {
      setLoading((prev) => ({ ...prev, processPayment: false }));
      handleShowSnackBar({ msg: `Failed to pay by New Guard Credit Card! Error: ${getErrMsg(err)}` }, enqueueSnackbar);
      setLoading(false);

      return err;
    }
  };

  useEffect(() => {
    (async () => {
      setLoading((prev) => ({ ...prev, fetchCCTypes: true }));
      try {
        const result = await paymentService.get.publicCCTypes(publicCredentails);
        setCCTypes(result);
        setLoading((prev) => ({ ...prev, fetchCCTypes: false }));

        return result;
      } catch (err) {
        setLoading((prev) => ({ ...prev, fetchCCTypes: false }));

        return err;
      }
    })();
  }, [accessToken]);

  return { loading, ccTypes, payByNewguardCC };
};

export default usePayments;
