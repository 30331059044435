/** @format */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert, AlertTitle, Button, Link, Paper, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material';
import { CreditCard } from '@mui/icons-material';

import { openModal } from 'reducers/modalSlice';

import LedgerList from './LedgerList';
import PaymentModal from './PaymentModal';
import useStyles from './styles';

const ViolationLedger = ({ agency, amountOwed, citationId, ledgers, loading, status, violationNumber, violator }) => {
  const minWidthReached = useMediaQuery('(min-width:480px)');
  const classes = useStyles({ minWidthReached });
  const dispatch = useDispatch();
  const [paymentRes, setPaymentRes] = useState(null);

  return (
    <Paper className={classes.root}>
      <Stack spacing={1}>
        <Typography variant={minWidthReached ? 'h2' : 'h3'} className={classes.ledgerHeader}>
          Pay Violation Here
        </Typography>
        <Typography variant='h6' className={classes.ledgerHeader}>
          Violation Ledger ({ledgers.length} items)
        </Typography>
        {loading ? (
          <Skeleton animation='wave' variant='rectangle' height={100} />
        ) : (
          <>
            <LedgerList ledgers={ledgers} amountOwed={amountOwed} webOption={agency?.webOption} />
            {agency.mayPopPaymentUrl && (
              <Link href={agency.mayPopPaymentUrl} target='_new' style={{ textAlign: 'center' }} underline='none'>
                Go to maypop payment website
              </Link>
            )}
            <Button
              fullWidth
              color='success'
              size='large'
              variant='contained'
              endIcon={<CreditCard />}
              disabled={
                ['indorqueue', 'collections', 'internallyopen'].includes(status?.toLowerCase().replaceAll(' ', '')) ||
                paymentRes?.approvalCode ||
                paymentRes?.response?.status === 504 ||
                amountOwed === 0
              }
              onClick={() =>
                dispatch(
                  openModal(
                    <PaymentModal
                      violator={violator}
                      agencyId={agency?.id}
                      ledgers={ledgers}
                      invoiceNumber={violationNumber}
                      citationId={citationId}
                      amountOwed={amountOwed}
                      webOption={agency?.webOption}
                      setPaymentRes={setPaymentRes}
                    />,
                  ),
                )
              }>
              Click to pay violation
            </Button>
            {status.toLowerCase() === 'indorqueue' && (
              <Alert variant='outlined' severity='error' className={classes.alert}>
                <strong>
                  Payment cannot be made online. Your account has been sent to the Department of Revenue. Please call
                  855-252-0086 to make a payment by phone or mail your payment to: 4411 Oakwood Dr. Chattanooga, TN
                  37416
                </strong>
              </Alert>
            )}
            {status.toLowerCase() === 'collections' && (
              <Alert variant='outlined' severity='error' className={classes.alert}>
                <strong>
                  Your citation has been sent to collections. Please contact Municipal Collections of America at
                  877-751-7115 to make a payment, or see the full total.
                </strong>
              </Alert>
            )}
            {paymentRes?.approvalCode && (
              <Alert variant='outlined' severity='success' className={classes.alert}>
                <AlertTitle>Payment Successful!</AlertTitle>
                The payment of <strong>${paymentRes?.amount}</strong> was made successfully by{' '}
                <strong>************{paymentRes?.creditCardNumber}</strong>.<br />
                The approval code is <strong>{paymentRes?.approvalCode}</strong>.<br />
                <br />
                Refresh the page to see the new change in the Violation Ledger.
              </Alert>
            )}
            {paymentRes?.response?.status === 504 && (
              <Alert severity='error' className={classes.alert}>
                <AlertTitle>ATTENTION!</AlertTitle>
                {paymentRes?.response?.data?.message}
              </Alert>
            )}
          </>
        )}
      </Stack>
    </Paper>
  );
};

ViolationLedger.propTypes = {
  violator: PropTypes.shape({}),
  violationNumber: PropTypes.string,
  agency: PropTypes.shape({
    id: PropTypes.string,
    mayPopPaymentUrl: PropTypes.string,
    webOption: PropTypes.shape({}),
  }),
  ledgers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      action: PropTypes.shape({ type: PropTypes.shape({ description: PropTypes.string }) }),
      amount: PropTypes.number,
      user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      dateTime: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  citationId: PropTypes.string,
  amountOwed: PropTypes.number,
  status: PropTypes.string,
};

ViolationLedger.defaultProps = {
  violator: {},
  violationNumber: '',
  agency: {},
  ledgers: [],
  loading: false,
  citationId: '',
  amountOwed: 0,
  status: '',
};

export default ViolationLedger;
