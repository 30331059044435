/** @format */

import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  imageContainer: {
    overflow: 'auto',
    marginTop: theme.spacing(2),
    width: '100%',
    '& img': { marginBottom: theme.spacing(2) },
  },
  videoContainer: {
    position: 'relative',
    cursor: 'pointer',
  },
  videoButton: {
    position: 'absolute',
    top: '44%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& svg': {
      width: 100,
      height: 100,
      stroke: 'azure',
      strokeWidth: 1,
    },
  },
}));
