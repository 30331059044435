/** @format */

const common = {
  // hoisted at top because used below
  light: {
    gray: '#D3D3D3',
    white: '#FFFFFF',
  },
  dark: {
    gray: '#D3D3D3',
    white: '#FFFFFF',
  },
  white: '#ffffff',
  black: '#000000',
};

const invert = {
  light: '#f9f9f9',
  dark: '#333',
};

const text = {
  // hoisted at top because used below
  dark: '#1A1C1E',
  light: '#F2F8FF',
  disabled: {
    dark: '#9DA1A6',
    light: '#C2C6CC',
  },
  icon: {
    light: '#9EA3A6',
    main: '#3C3E3F',
  },
  metadata: {
    dark: '#5D5F60',
  },
};

const primary = {
  light: {
    contrastText: text.light,
    dark: '#2264BA',
    light: '#5493E5',
    main: '#2978DF',
  },
  dark: {
    contrastText: text.light,
    light: '#5B9FF7',
    main: '#5493E5',
    dark: '#2978DF',
  },
};

const secondary = {
  light: {
    contrastText: text.dark,
    dark: '#e5c593',
    main: '#ffdaa4',
  },
  dark: {
    contrastText: text.dark,
    dark: '#e5c593',
    main: '#ffdaa4',
  },
};

const error = {
  light: {
    contrastText: text.light,
    dark: '#C13737',
    light: '#E56C68',
    main: '#D94545',
  },
  dark: {
    contrastText: text.light,
    light: '#E56C68',
    main: '#D94545',
    dark: '#C13737',
  },
};

const warning = {
  light: {
    contrastText: text.dark,
    dark: '#CC8441',
    light: '#F5B071',
    main: '#F29C4D',
    border: '#FFFF00;',
  },
  dark: {
    contrastText: text.dark,
    light: '#F5B071',
    main: '#F29C4D',
    dark: '#CC8441',
    border: '#FFFF00;',
  },
};

const info = {
  light: {
    contrastText: text.dark,
    dark: '#B2A300',
    light: '#FFEF33',
    main: '#FFEA00',
  },
  dark: {
    contrastText: text.dark,
    light: '#FFE28D',
    main: '#FFDB71',
    dark: '#D9BA60',
  },
};

const success = {
  light: {
    contrastText: text.light,
    dark: '#388E3C',
    light: '#81C784',
    main: '#4CAF50',
  },
  dark: {
    contrastText: text.light,
    dark: '#3AA653',
    light: '#60D880',
    main: '#47CC66',
  },
};

const purple = {
  light: {
    contrastText: text.light,
    dark: '#744D99',
    light: '#AF74E6',
    main: '#AF74E6',
  },
  dark: {
    contrastText: text.light,
    light: '#AF74E6',
    dark: '#744D99',
  },
};

const highlight = {
  text: {
    red: '#ff4444',
  },
  background: {
    red: '#ffdddd',
  },
};

// TODO: named redesign for now, can change to a cooler name later
const redesign = {
  activeLightGray: '#DFECFF',
  blue10: '#F8FAFC',
  gray30: '#E0E0E0',
  gray70: '#808191',
  gray80: '#64748B',
  green: '#4CAF50',
  green10: '#F6FFF7',
  green30: '#BDDCBE',
  lightBlue: '#A0D7E7',
  navyBlue70: '#425368',
  orange: '#F59E0B',
  primaryBlack: '#212121',
  primaryBlue: '#43A7FF',
  red: '#EB5757',
  red10: '#FFF8F8',
  red30: '#F8CACA',
  red50: '#FF6E6C',
  white: '#FFFFFF',
  yellow: '#FFCE73',
  dark: { background: '#1D2634' },
};

const light = {
  mode: 'light',
  common: common.light,
  invert: invert.light,
  primary: primary.light,
  secondary: secondary.light,
  error: error.light,
  warning: warning.light,
  info: info.light,
  success: success.light,
  purple: purple.light,
  highlight,
  text: {
    primary: '#1A1C1E',
    hint: '#9DA1A6',
    icon: '#9DA1A6',
    error: error.light,
    disabled: {
      main: '#9DA1A6',
      dark: '#505257',
    },
    input: {
      label: '#1A1C1EDE',
      placeholder: '3C3E3F',
    },
  },
  divider: '#D5DCE0',
  background: {
    default: '#F9F9FC',
    paper: '#FFFFFF',
    level1: '#e6e6e6',
    level2: '#f0f0f0',
    disabled: '#7F838A',
    accordionItem: {
      disabled: '#0000001f',
    },
    modal: {
      overlay: '#1B243033',
    },
  },
  lifecycle: {
    staged: success.light,
    imported: error.light,
    review: error.light,
    approval: info.light,
    printing: primary.light,
  },
  ui: {
    border: redesign.gray30,
    input: '#0000006b',
    slider: '#e4ecf0',
  },
  avatar: {
    background: redesign.activeLightGray,
    text: redesign.primaryBlue,
  },
  ledger: {
    positive: {
      border: redesign.green,
      background: redesign.green10,
    },
    negative: {
      border: redesign.red,
      background: redesign.red10,
    },
  },
  file: { background: redesign.blue10 },
  lookupViolationInput: { color: redesign.primaryBlue, borderColor: redesign.gray30 },
};

const dark = {
  mode: 'dark',
  common: common.dark,
  invert: invert.dark,
  primary: primary.dark,
  secondary: secondary.dark,
  error: error.dark,
  warning: warning.dark,
  info: info.dark,
  success: success.dark,
  purple: purple.dark,
  highlight,
  text: {
    primary: '#F2F8FF',
    hint: '#9DA1A6',
    error: error.dark,
    icon: '#9DA1A6',
    disabled: {
      main: '#9DA1A6',
      dark: '#505257',
    },
    input: {
      label: '#F2F8FFB3',
      placeholder: '#F2F8FFCC',
    },
  },
  background: {
    default: '#2B3440',
    paper: '#1B2430',
    level1: '#1B2430',
    level2: '#232F3E',
    accordionItem: {
      disabled: '#0000001f',
    },
    modal: {
      overlay: '#1b243066',
    },
  },
  lifecycle: {
    staged: success.dark,
    imported: error.dark,
    review: error.dark,
    approval: info.dark,
    printing: primary.dark,
  },
  divider: '#ffffff3b',
  ui: {
    border: redesign.navyBlue70,
    input: '#ffffffb3',
    slider: '#41474e',
  },
  avatar: {
    background: redesign.gray80, // TODO: temp color, missing in new color set
    text: redesign.primaryBlue,
  },
  ledger: {
    positive: {
      border: redesign.green,
      background: redesign.dark.background,
    },
    negative: {
      border: redesign.red,
      background: redesign.dark.background,
    },
  },
  file: { background: redesign.dark.background },
  lookupViolationInput: { color: '#bbb', borderColor: 'transparent' },
};

export default { dark, light };
