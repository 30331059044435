/** @format */

const DOMAIN_ENV = {
  staging: 'staging.bline.dev',
  production: 'newguard.net',
};

export const domain = DOMAIN_ENV[process.env.REACT_APP_IIS_ENV] || 'development.bline.dev';

const DEFAULT_CONFIG = {
  authority: 'https://id2.bline.dev',
  loadUserInfo: true,
  response_type: 'code',
  scope: 'openid profile BLS.Web',
  automaticSilentRenew: true,
};

const configuration =
  process.env.NODE_ENV === 'development'
    ? {
        ...DEFAULT_CONFIG,
        client_id: 'BLS.LTI.WebJSClient.DEVELOPER',
        post_logout_redirect_uri: 'https://developer:3000/',
        redirect_uri: 'https://developer:3000/authentication/callback',
        silent_redirect_uri: 'https://developer:3000/authentication/silent_callback',
      }
    : {
        ...DEFAULT_CONFIG,
        client_id: 'BLS.WebJSClient.ngfe',
        post_logout_redirect_uri: `https://${domain}/loggedOut`,
        redirect_uri: `https://${domain}/authentication/callback`,
        silent_redirect_uri: `https://${domain}/authentication/silent_callback`,
      };

export const sessionInactivityTimeLimit = {
  // warn of logout after 5 min, then log out after 60 more seconds
  logoutTimeInSeconds: 60,
  warnOfImpendingLogoutInSeconds: 300 * 1000,
};

export default configuration;

export const gatewayUrl = `https://${domain}/ApiGateway`;
export const mockUrl = 'http://localhost:3004';
