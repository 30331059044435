/** @format */

import { useState } from 'react';
import { useSnackbar } from 'notistack';

import violationService from 'services/violationService';
import { DEFAULT_ANCHOR_ORIGIN } from 'utils/constants';

const useViolations = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [violation, setViolation] = useState({});
  const [violationImages, setViolationImages] = useState([]);
  const [violator, setViolator] = useState({});

  const getViolation = async (formData) => {
    setLoading(true);

    try {
      const violationRes = await violationService.get.publicViolation(formData);
      setViolation(violationRes);

      const violatorRes = await violationService.get.publicViolationOwner(
        formData,
        violationRes.accountId,
        violationRes.eventId,
      );
      setViolator(violatorRes);

      const violationImagesRes = await violationService.get.publicViolationImages(formData, violationRes.eventId);
      setViolationImages(violationImagesRes);

      setLoading(false);
      enqueueSnackbar('Your violation details have been retrieved!', {
        variant: 'success',
        anchorOrigin: DEFAULT_ANCHOR_ORIGIN,
      });

      return { ...violationRes, violator: violatorRes };
    } catch (err) {
      setLoading(false);

      return {
        type: 'error',
        msg: 'The citation number and PIN you entered did not match our records. Please double-check and try again.',
      };
    }
  };

  return { loading, violation, violationImages, violator, getViolation };
};

export default useViolations;
