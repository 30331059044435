/** @format */

import axios from 'axios';

import { gatewayUrl, mockUrl } from 'config/authConfig';
import { violationMock as mockSource } from 'config/enableMock';

const get = {
  publicViolation: (formData, mock = mockSource.get.includes('publicViolation')) =>
    new Promise((resolve, reject) => {
      const { violationNumber, accessKey } = formData;
      if (!accessKey) {
        reject(new Error('Access Key Required'));
        return false;
      }

      const encodedCredentials = Buffer.from(`${violationNumber}:${accessKey}`).toString('base64');

      return axios
        .get(`${mock ? mockUrl : gatewayUrl}/public/violation`, {
          headers: { Authorization: `Basic ${encodedCredentials}` },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    }),

  publicViolationImages: (formData, eventId, mock = mockSource.get.includes('violationImagesById')) =>
    new Promise((resolve, reject) => {
      const { violationNumber, accessKey } = formData;
      if (!accessKey) {
        reject(new Error('Access Key Required'));
        return false;
      }

      if (!eventId) {
        reject(new Error('Event ID Required'));
        return false;
      }

      const encodedCredentials = Buffer.from(`${violationNumber}:${accessKey}`).toString('base64');

      return axios
        .get(`${mock ? mockUrl : gatewayUrl}/Violations/public/violation/${eventId}/images`, {
          headers: { Authorization: `Basic ${encodedCredentials}` },
        })
        .then((result) => resolve(result.data))
        .catch((err) => reject(err));
    }),

  publicViolationOwner: (formData, accountId, eventId, mock = mockSource.get.includes('publicViolationOwner')) =>
    new Promise((resolve, reject) => {
      const { violationNumber, accessKey } = formData;
      if (!accessKey) {
        reject(new Error('Access Key Required'));
        return false;
      }

      if (!eventId) {
        reject(new Error('Event ID Required'));
        return false;
      }

      const encodedCredentials = Buffer.from(`${violationNumber}:${accessKey}`).toString('base64');

      return axios
        .get(
          `${mock ? mockUrl : gatewayUrl}/Violations/public/violation/accountId/${accountId}/eventId/${eventId}/owner`,
          {
            headers: { Authorization: `Basic ${encodedCredentials}` },
          },
        )
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    }),
};

export default { get };
