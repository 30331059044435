/** @format */

import { makeStyles } from '@mui/styles';

export const usePaymentModalStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    maxWidth: '88vw',
    maxHeight: '88vh',
    overflowY: 'auto',
    paddingRight: ({ minWidthReached }) => theme.spacing(minWidthReached ? 2 : 1),
  },
  alert: { filter: 'unset' },
  cancelButton: { width: ({ minWidthReached }) => (minWidthReached ? 160 : 100) },
  payButton: { width: ({ minWidthReached }) => (minWidthReached ? 200 : 160) },
  tabContainer: {
    background: theme.palette.background.level1,
    borderRadius: 4,
    margin: theme.spacing(2, 0, 0, 3),
    padding: theme.spacing(4, 4, 5, 4),
  },
}));
