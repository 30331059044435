/** @format */

import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.ui.border}`,
    padding: theme.spacing(1),
    '& .MuiInputAdornment-root': { '& p': { color: theme.palette.primary.main } },
    '& input': {
      textAlign: 'right',
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
}));
