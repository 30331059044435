/** @format */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  message: '',
  severity: 'info',
  anchor: { vertical: 'bottom', horizontal: 'left' },
  duration: 4000,
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    getSnackbar: (state, { payload: { message, severity, anchor, duration } }) => {
      state.open = true;
      if (message) state.message = message;
      if (severity) state.severity = severity;
      if (anchor && anchor.vertical) state.anchor.vertical = anchor.vertical;
      if (anchor && anchor.horizontal) state.anchor.horizontal = anchor.horizontal;
      if (duration) state.duration = duration;
    },
    hideSnackbar: (state) => {
      state.open = false;
    },
    initializeSnackbar: (state) => {
      state.message = '';
      state.severity = 'info';
      state.anchor = { vertical: 'bottom', horizontal: 'left' };
      state.duration = 4000;
    },
  },
});

const { getSnackbar, hideSnackbar, initializeSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;

export const showSnackbar = ({ message, severity, anchor, duration }) => (dispatch) => {
  dispatch(getSnackbar({ message, severity, anchor, duration }));
};

export const clearSnackbar = () => (dispatch) => {
  dispatch(hideSnackbar());
};

export const resetSnackbar = () => (dispatch) => {
  dispatch(initializeSnackbar());
};
