/** @format */

import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ children, index, value, ...rest }) => (
  <div hidden={value !== index} role='tabpanel' {...rest}>
    {value === index && children}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

TabPanel.defaultProps = {
  children: null,
};

export default TabPanel;
