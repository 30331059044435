/** @format */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components/macro';
import { create } from 'jss';
import { Button, IconButton } from '@mui/material';
import { Error } from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { StylesProvider, jssPreset } from '@mui/styles';
import { AuthenticationProvider, InMemoryWebStorage } from '@axa-fr/react-oidc-context';
import { Wrapper } from '@googlemaps/react-wrapper';
import { SnackbarProvider } from 'notistack';
import { X } from 'react-feather';

import { GlobalSnackbar, AuthModal, Modal, InactivityModal } from 'components';
import authConfig, { sessionInactivityTimeLimit } from 'config/authConfig';
import Routes from 'routes';
import createTheme from 'theme';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});

export const AuthComponents = {
  configuration: authConfig,
  authenticating: () => <AuthModal loading>Verifying your credentials</AuthModal>,
  notAuthorized: () => <AuthModal>Not Authorized</AuthModal>,
  notAuthenticated: () => <AuthModal>Not Authenticated</AuthModal>,
  callbackComponentOverride: () => <AuthModal loading>Returning To Application</AuthModal>,
  sessionLostComponent: () => (
    <AuthModal>
      Your session has been disconnected, please signin again
      <br />
      <Button pt={1} href={authConfig.post_logout_redirect_uri}>
        Reconnect Now
      </Button>
    </AuthModal>
  ),
  UserStore: InMemoryWebStorage,
  isEnabled: true,
};

function App() {
  const theme = useSelector((state) => state.theme);

  const [isUserActive, setIsUserActive] = useState(true);

  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  const snackBarAction = (key) => (
    <IconButton onClick={onClickDismiss(key)}>
      <X size={16} color='white' />
    </IconButton>
  );

  // user inactivity effect
  useEffect(() => {
    let activityTimer;

    const resetActivityTimer = () => {
      // reset timer to defaults
      setIsUserActive(true);
      clearTimeout(activityTimer);

      // restart inactivity timer
      activityTimer = setTimeout(() => {
        setIsUserActive(false);
      }, sessionInactivityTimeLimit.warnOfImpendingLogoutInSeconds);
    };

    resetActivityTimer();
    window.addEventListener('mousemove', resetActivityTimer);
    window.addEventListener('keydown', resetActivityTimer);
  }, []);

  return (
    <>
      <Helmet
        titleTemplate='%s | NEW GUARD - Law Enforcement Software Solutions'
        defaultTitle='NEW GUARD | Law Enforcement Software Solutions'
      />
      <StylesProvider jss={jss}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
              <ThemeProvider theme={createTheme(theme.currentTheme)}>
                <AuthenticationProvider {...AuthComponents}>
                  <GlobalSnackbar />
                  <SnackbarProvider
                    maxSnack={3}
                    ref={notistackRef}
                    action={snackBarAction}
                    iconVariant={{ error: <Error fontSize='small' sx={{ marginRight: 1 }} /> }}>
                    <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} libraries={['places']}>
                      <Routes />
                      <Modal />
                    </Wrapper>
                  </SnackbarProvider>
                  <InactivityModal open={!isUserActive} logoutTime={sessionInactivityTimeLimit.logoutTimeInSeconds} />
                </AuthenticationProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StylesProvider>
    </>
  );
}

export default App;
