/** @format */

import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  backdrop: { backgroundColor: 'rgba(27, 36, 48, .9)' },
  carousel: { height: '100vh', padding: theme.spacing(2) },
  clearBtn: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  iconBtn: {
    background: '#9EA3A6',
    '&:hover': { background: '#C2C6CC' },
  },
  iconSvg: {
    fontSize: '4vh',
    [theme.breakpoints.down(900)]: { fontSize: '2vw' },
  },
  modalImg: {
    maxWidth: '80vw',
    maxHeight: '80vh',
    objectFit: 'cover',
    borderRadius: 8,
    border: `1px solid ${theme.palette.ui.border}`,
    [theme.breakpoints.down(900)]: { maxWidth: '70vw' },
  },
}));
