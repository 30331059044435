/** @format */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert, AlertTitle, Button, Divider, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { Close, CreditCard } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { round } from 'lodash';

import { AsyncButton } from 'components';
import { usePayments } from 'hooks';
import { closeModal } from 'reducers/modalSlice';
import { getFullName, getFullAddress } from 'utils';
import { states } from 'mocks';

import LedgerList from '../LedgerList';
import CreditCardForm from './CreditCardForm';
import { usePaymentModalStyles } from './styles';
import { paymentModalSchema } from './schemas';

const PaymentModal = ({
  agencyId,
  amountOwed,
  citationId,
  invoiceNumber,
  ledgers,
  violator: { ownerName, address },
  webOption,
  setPaymentRes,
}) => {
  const dispatch = useDispatch();
  const minWidthReached = useMediaQuery('(min-width:480px)');
  const classes = usePaymentModalStyles({ minWidthReached });
  const { loading, ccTypes, payByNewguardCC } = usePayments();

  const { control, errors, trigger, getValues, setValue } = useForm({
    mode: 'onChange',
    resolver: yupResolver(paymentModalSchema),
  });

  const [errorMsg, setErrorMsg] = useState('');

  const handleFillForm = () => {
    setValue('firstName', ownerName?.first);
    setValue('lastName', ownerName?.last);
    setValue('address', address?.street1);
    setValue('city', address?.city);
    setValue('zip', address?.zip?.slice(0, 5));
    setValue(
      'state',
      states.find((state) => state.id === address?.state),
    );
  };

  const handleSubmit = async () => {
    const formIsValid = await trigger();

    if (formIsValid) {
      const formData = getValues();
      const totalDue = ledgers.reduce((prev, curr) => ({ amount: prev.amount + curr.amount }), { amount: 0 });

      const formattedFormData = { ...formData, agencyId, invoiceNumber };

      formattedFormData.amount = round(totalDue.amount + webOption?.ccFee, 2);
      formattedFormData.creditCardTypeId = formData.cardType?.id;
      formattedFormData.citationId = citationId;

      const result = await payByNewguardCC(formattedFormData);

      if (result?.approvalCode || result?.response?.status === 504) {
        setPaymentRes(result);
        if (result?.response?.status === 504) setErrorMsg(result?.response?.data?.message);
        if (result?.approvalCode) dispatch(closeModal());
      }
    }
  };

  return (
    <Grid container direction='column' className={classes.root} spacing={3} wrap='nowrap'>
      <Grid container item justifyContent='space-between' alignItems='center'>
        <Typography variant={minWidthReached ? 'h3' : 'h4'}>Payment</Typography>
        <IconButton onClick={() => dispatch(closeModal())} size='large'>
          <Close />
        </IconButton>
      </Grid>

      <Grid item>
        <Stack spacing={1}>
          <Typography variant='h6'>VIOLATION DETAILS</Typography>
          <Typography variant={minWidthReached ? 'h3' : 'h4'}>
            {getFullName({ first: ownerName?.first, last: ownerName?.last })}
          </Typography>
          <Typography>
            {getFullAddress({
              street: address?.street1,
              city: address?.city,
              state: address?.state,
              zipCode: address?.zip,
            })}
          </Typography>
        </Stack>
      </Grid>

      <Grid item>
        <LedgerList ledgers={ledgers} amountOwed={amountOwed} webOption={webOption} />
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid container item justifyContent='space-between' alignItems='center'>
        <Typography variant='h6'>PAYMENT METHOD</Typography>
        <Button className={classes.retryButton} onClick={handleFillForm}>
          Fill form using violator data
        </Button>
      </Grid>

      {errorMsg && (
        <Grid item>
          <Alert severity='error' className={classes.alert}>
            <AlertTitle>ATTENTION!</AlertTitle>
            {errorMsg}
          </Alert>
        </Grid>
      )}

      <Stack className={classes.tabContainer}>
        <CreditCardForm ccTypes={ccTypes} control={control} errors={errors} setValue={setValue} />
      </Stack>

      <Grid container item justifyContent='space-between'>
        <Button
          variant='contained'
          className={classes.cancelButton}
          onClick={() => dispatch(closeModal())}
          size='large'>
          Cancel
        </Button>
        <AsyncButton
          disabled={!!errorMsg}
          size='large'
          variant='contained'
          color='success'
          startIcon={<CreditCard />}
          className={classes.payButton}
          loading={loading.processPayment}
          loadingText='Processing Payment...'
          onClick={handleSubmit}>
          PAY NOW
        </AsyncButton>
      </Grid>
    </Grid>
  );
};

PaymentModal.propTypes = {
  agencyId: PropTypes.string,
  amountOwed: PropTypes.number,
  citationId: PropTypes.string,
  invoiceNumber: PropTypes.string,
  ledgers: PropTypes.arrayOf(PropTypes.object),
  violator: PropTypes.shape({
    ownerName: PropTypes.shape({
      first: PropTypes.string,
      last: PropTypes.string,
    }),
    address: PropTypes.shape({
      street1: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
    }),
  }),
  webOption: PropTypes.shape({ ccFee: PropTypes.number }),
  setPaymentRes: PropTypes.func,
};

PaymentModal.defaultProps = {
  agencyId: '',
  amountOwed: 0,
  citationId: '',
  invoiceNumber: '',
  ledgers: [],
  violator: {},
  webOption: {},
  setPaymentRes: () => {},
};

export default PaymentModal;
