/** @format */

import * as yup from 'yup';

export const paymentModalSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  creditCardNumber: yup.string().required('Card number is required'),
  cardType: yup.object().nullable().required('Card type is required'),
  expirationDate: yup
    .string()
    .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, 'Expiration date must be in MM/YY format')
    .required('Card expiration date is required'),
  ccv: yup.string().required('Card CCV is required'),
  address: yup.string().required('Billing address is required'),
  city: yup.string().required('Billing city is required'),
  zip: yup
    .string()
    .matches(/^[0-9]+$/, 'Billing zip must be only digits')
    .min(5, 'Billing zip must be exactly 5 digits')
    .max(5, 'Billing zip must be exactly 5 digits'),
  state: yup.object().nullable().required('Billing state is required'),
  amount: yup.string().required('Amount is required'),
});
