/** @format */

import axios from 'axios';

import { gatewayUrl, mockUrl } from 'config/authConfig';
import { paymentService as mockSource } from 'config/enableMock';

const get = {
  publicCCTypes: (credentails, mock = mockSource.post.includes('publicCCTypes')) =>
    new Promise((resolve, reject) => {
      const { violationNumber, accessKey } = credentails;
      if (!accessKey || !violationNumber) {
        reject(new Error('Violation Number and Access Key Required'));
        return false;
      }

      const encodedCredentials = Buffer.from(`${violationNumber}:${accessKey}`).toString('base64');

      return axios
        .get(`${mock ? mockUrl : gatewayUrl}/payment/api/CreditCardType/public/types`, {
          headers: { Authorization: `Basic ${encodedCredentials}` },
        })
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    }),
};

const post = {
  publicNewguardCC: (credentails, formData, mock = mockSource.post.includes('publicNewguardCC')) =>
    new Promise((resolve, reject) => {
      const { violationNumber, accessKey } = credentails;
      if (!accessKey || !violationNumber) {
        reject(new Error('Violation Number and Access Key Required'));
        return false;
      }

      const encodedCredentials = Buffer.from(`${violationNumber}:${accessKey}`).toString('base64');

      return axios
        .post(`${mock ? mockUrl : gatewayUrl}/Payments/public/newguard/cc`, formData, {
          headers: { Authorization: `Basic ${encodedCredentials}` },
        })
        .then((response) => resolve(response.data))
        .catch((err) => reject(err));
    }),
};

export default { get, post };
