/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Skeleton, Typography, useMediaQuery } from '@mui/material';

import { getFullName, getFullAddress } from 'utils';

import useStyles from './styles';

const VehicleOwnerInfo = ({ violator: { address, ownerName } }) => {
  const minWidthReached = useMediaQuery('(min-width:480px)');
  const classes = useStyles({ minWidthReached });

  return (
    <Paper className={classes.root} elevation={3}>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Typography variant='h6'>Vehicle Owner Info</Typography>
        </Grid>
        <Grid container item spacing={2} alignItems='center'>
          <Grid container item direction='column' spacing={1} xs>
            <Grid item>
              {ownerName ? (
                <Typography variant={minWidthReached ? 'h3' : 'h4'}>
                  {getFullName({ first: ownerName?.first, last: ownerName?.last })}
                </Typography>
              ) : (
                <Skeleton animation='wave' variant='rectangle' height={30} width='80%' />
              )}
            </Grid>
            <Grid item>
              {address ? (
                <Typography>
                  {getFullAddress({
                    street: address?.street1,
                    city: address?.city,
                    state: address?.state,
                    zipCode: address?.zip,
                  })}
                </Typography>
              ) : (
                <Skeleton animation='wave' variant='rectangle' height={20} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

VehicleOwnerInfo.propTypes = {
  violator: PropTypes.shape({
    ownerName: PropTypes.shape({
      first: PropTypes.string,
      middle: PropTypes.string,
      last: PropTypes.string,
    }),
    address: PropTypes.shape({
      street1: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
    }),
    violations: PropTypes.arrayOf(PropTypes.object),
  }),
};

VehicleOwnerInfo.defaultProps = { violator: {} };

export default VehicleOwnerInfo;
