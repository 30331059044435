/** @format */

import React from 'react';

// theme
export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
};

// colors
export const FALLBACK_COLOR = '#000';

export const VIOLATION_STATUS_LOOKUP_CARD_COLOR = {
  'admin hold': '#2F6BBC',
  approved: '#78AD50',
  'citation batch unlocked in approve': '#F0381C',
  closed: '#555',
  collections: '#B42812',
  'department of revenue': '#F03567',
  'locked for approval': '#3FAF57',
  'Locked for Review': '#F2F2F2',
  'marked approve later': '#FBE89F',
  'marked exception in review': '#F2F2F2',
  'marked review later': '#FAE582',
  opened: '#78AD50',
  registered: '#6997D2',
  reviewed: '#F2F2F2',
  'spoiled after approval': '#F2F2F2',
  'spoiled during approval': '#F2F2F2',
  voided: '#6C2D9C',
  warning: '#EF7225',
};

// renders
export const PENDING_API_DATA = <span style={{ color: 'red' }}>Pending API data</span>;

// dates
// TODO: the date 01/01/2000 should come from BE
export const EARLIEST_LIFECYCLE_DATE = new Date('01/01/2000').getTime();

export const TOKEN_TYPE = 'Bearer';

export const NO_LEGEND = -1;

export const DATE_FORMAT = 'M/d/yyyy';

// Event Review
export const EVENT = {
  STATUS: {
    UNDER_REVIEW: 20,
    SKIPPED: 30,
    ACCEPTED: 40,
    REJECTED: 50,
  },
};

export const DEFAULT_ANCHOR_ORIGIN = { vertical: 'top', horizontal: 'right' };
