/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    '& .MuiButton-root': {
      cursor: (props) => (props.editable ? 'pointer' : 'default'),
      width: 40,
    },
  },
});

const weekDays = ['S', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'];

const WeekDayPicker = ({ editable, id, rules, setFormData }) => {
  const classes = useStyles({ editable });
  const selectedRule = rules.find((rule) => id === rule.id);

  return (
    <ButtonGroup
      className={classes.root}
      disableElevation
      disableFocusRipple={!editable}
      disableRipple={!editable}
      size='small'>
      {weekDays.map((day, i) => (
        <Button
          color={selectedRule && selectedRule.daysActive.includes(i) ? 'primary' : 'secondary'}
          onClick={() =>
            setFormData((prevState) => ({
              ...prevState,
              rules: prevState.rules.map((rule) =>
                rule.id === id
                  ? {
                      ...rule,
                      daysActive: rule.daysActive.includes(i)
                        ? rule.daysActive.filter((num) => num !== i)
                        : [...rule.daysActive, i],
                    }
                  : rule,
              ),
            }))
          }
          key={day}
          value={i}
          variant={selectedRule && selectedRule.daysActive.includes(i) ? 'contained' : 'outlined'}>
          {day}
        </Button>
      ))}
    </ButtonGroup>
  );
};

WeekDayPicker.propTypes = {
  editable: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rules: PropTypes.arrayOf(PropTypes.object),
  setFormData: PropTypes.func,
};

WeekDayPicker.defaultProps = {
  editable: false,
  id: 0,
  rules: [],
  setFormData: () => {},
};

export default WeekDayPicker;
