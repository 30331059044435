/** @format */

import { createSlice } from '@reduxjs/toolkit';
import { THEMES } from 'utils/constants';

const userThemePreferences = JSON.parse(window.localStorage.getItem('theme'));
const initialState = {
  currentTheme: userThemePreferences ? userThemePreferences.preference : THEMES.DEFAULT,
  darkMode: userThemePreferences ? userThemePreferences.preference === THEMES.DARK : false,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      if (state.darkMode) {
        state.currentTheme = THEMES.DEFAULT;
        state.darkMode = false;
        window.localStorage.setItem('theme', JSON.stringify({ preference: THEMES.DEFAULT }));
      } else {
        state.currentTheme = THEMES.DARK;
        state.darkMode = true;
        window.localStorage.setItem('theme', JSON.stringify({ preference: THEMES.DARK }));
      }
    },
  },
});

export const { setTheme, toggleDarkMode } = themeSlice.actions;
export default themeSlice.reducer;
