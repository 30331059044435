/** @format */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, Stack, TextField, Typography, ClickAwayListener } from '@mui/material';
import { CheckCircleOutline, EditOutlined } from '@mui/icons-material';
import { startCase } from 'lodash';

import { LabelValue } from 'components';

import useStyles from './styles';

const EventInformation = ({ event, editable }) => {
  const classes = useStyles();
  const initialState = Object.fromEntries(Object.keys(event).map((key) => [key, false]));
  const [isEditing, setIsEditing] = useState(initialState);

  return (
    <Stack spacing={2} py={2}>
      <Typography variant='h5'>Event Information</Typography>
      <Stack className={classes.container}>
        {Object.entries(event).map(([key, value]) =>
          isEditing[key] ? (
            <Stack key={key} direction='row' spacing={1}>
              <ClickAwayListener
                mouseEvent='onMouseDown'
                touchEvent='onTouchStart'
                onClickAway={() => setIsEditing(initialState)}>
                <TextField
                  autoFocus
                  defaultValue={value}
                  fullWidth
                  InputProps={{ startAdornment: <InputAdornment>{startCase(key)}</InputAdornment> }}
                  size='small'
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') setIsEditing(initialState);
                  }}
                />
              </ClickAwayListener>
              <IconButton
                color='success'
                onClick={() => {
                  setIsEditing(initialState);
                  setIsEditing((prevState) => ({ ...prevState, [key]: true }));
                }}>
                <CheckCircleOutline fontSize='small' />
              </IconButton>
            </Stack>
          ) : (
            <Stack key={key} direction='row' sx={{ my: editable ? 0 : 1 }}>
              <LabelValue label={startCase(key)} value={value} />
              {editable && (
                <IconButton
                  color='primary'
                  onClick={() => {
                    setIsEditing(initialState);
                    setIsEditing((prevState) => ({ ...prevState, [key]: true }));
                  }}>
                  <EditOutlined fontSize='small' />
                </IconButton>
              )}
            </Stack>
          ),
        )}
      </Stack>
    </Stack>
  );
};

EventInformation.propTypes = {
  editable: PropTypes.bool,
  event: PropTypes.shape({}),
};

EventInformation.defaultProps = {
  editable: false,
  event: {},
};

export default EventInformation;
