/** @format */

import { THEMES } from '../utils/constants';
import theme from './bli';

const overrides = (themeName) => ({
  MuiGrid: {
    root: {
      '& ::-webkit-scrollbar': {
        background: themeName === THEMES.DARK ? theme.palette.dark.ui.slider : theme.palette.light.ui.slider,
        borderRadius: 999,
        height: 8,
        width: 8,
      },
      '& ::-webkit-scrollbar-thumb': {
        background: theme.palette.light.secondary.main,
        borderRadius: 16,
      },
      '& ::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.light.secondary.dark,
      },
    },
  },
  MuiDataGrid: {
    root: {
      border: 'unset',
      '& .MuiDataGrid-columnHeaders': {
        background: theme.palette[themeName === THEMES.DARK ? 'dark' : 'light'].background.paper,
        borderBottom: 'unset',
        borderRadius: 4,
      },
      '& .MuiDataGrid-columnHeader': {
        '&:focus': { outline: 'unset' },
        '&:focus-within': { outline: 'unset' },
      },
      '& .MuiDataGrid-colCellTitleContainer': { justifyContent: 'space-between' },
      '& .MuiDataGrid-colCellSortable': { outline: 'unset' },
      '& .MuiDataGrid-colCell:focus': { outline: 'unset' },
      '& .MuiDataGrid-cell': {
        borderBottom: 'unset',
        outline: 'unset',
        cursor: 'pointer',
        '&:focus': { outline: 'unset' },
        '&:focus-within': { outline: 'unset' },
      },
      '& ::-webkit-scrollbar': {
        background: themeName === THEMES.DARK ? theme.palette.dark.ui.slider : theme.palette.light.ui.slider,
      },
      '& ::-webkit-scrollbar-thumb': {
        background: theme.palette.light.secondary.main,
        border: 'unset',
      },
    },
  },
  MuiCard: {
    root: {
      borderRadius: '6px',
      boxShadow: 'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
    },
  },
  MuiCardHeader: {
    action: {
      marginTop: '-4px',
      marginRight: '-4px',
    },
  },
  MuiPickersDay: {
    day: {
      fontWeight: '300',
    },
  },
  MuiPickersYear: {
    root: {
      height: '64px',
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: '6px',
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: 'transparent',
      '& > *': {
        backgroundColor: 'transparent',
      },
    },
    switchHeader: {
      marginTop: '2px',
      marginBottom: '4px',
    },
  },
  MuiPickersClock: {
    container: {
      margin: '32px 0 4px',
    },
  },
  MuiPickersClockNumber: {
    clockNumber: {
      left: 'calc(50% - 16px)',
      width: '32px',
      height: '32px',
    },
  },
  MuiPickerDTHeader: {
    dateHeader: {
      '& h4': {
        fontSize: '2.125rem',
        fontWeight: 400,
      },
    },
    timeHeader: {
      '& h3': {
        fontSize: '3rem',
        fontWeight: 400,
      },
    },
  },
  MuiPickersTimePicker: {
    hourMinuteLabel: {
      '& h2': {
        fontSize: '3.75rem',
        fontWeight: 300,
      },
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      '& h4': {
        fontSize: '2.125rem',
        fontWeight: 400,
      },
    },
  },
  MuiChip: {
    root: {
      borderRadius: '6px',
    },
  },
  MuiBackdrop: {
    root: {
      zIndex: -1,
    },
  },
  MuiPaper: {
    root: {
      boxShadow: 'unset',
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
  },
});

export default overrides;
