/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

const ControlledTextField = ({
  autoFocus,
  control,
  defaultValue,
  disabled,
  error,
  errorMessage,
  InputLabelProps,
  InputProps,
  inputProps,
  label,
  placeholder,
  name,
  multiline,
  rows,
  newValue,
  onEnterPress,
  setFormData,
  type,
  variant,
  parentName,
  childIndex,
}) => (
  <Controller
    control={control}
    defaultValue={defaultValue}
    name={parentName && childIndex ? `${parentName}-${name}${childIndex}` : name}
    render={({ onBlur, onChange, ref, value }) => (
      <TextField
        autoComplete='new-password'
        autoFocus={autoFocus}
        disabled={disabled}
        error={error}
        fullWidth
        helperText={errorMessage}
        InputLabelProps={InputLabelProps}
        InputProps={InputProps}
        inputProps={inputProps}
        inputRef={ref}
        label={label}
        placeholder={placeholder}
        multiline={multiline}
        rows={rows}
        onBlur={(e) => {
          setFormData((prev) =>
            parentName && childIndex
              ? {
                  ...prev,
                  [parentName]: {
                    ...prev[parentName],
                    [childIndex]: { ...prev[parentName][childIndex], [name]: e.target.value },
                  },
                }
              : { ...prev, [name]: e.target.value },
          );
          onBlur();
        }}
        onChange={onChange}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onEnterPress();
          }
        }}
        type={type}
        value={value || newValue}
        variant={variant}
      />
    )}
  />
);

ControlledTextField.propTypes = {
  autoFocus: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  InputLabelProps: PropTypes.shape({}),
  InputProps: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  newValue: PropTypes.string,
  onEnterPress: PropTypes.func,
  setFormData: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  parentName: PropTypes.string,
  childIndex: PropTypes.number,
};

ControlledTextField.defaultProps = {
  autoFocus: false,
  defaultValue: '',
  disabled: false,
  error: false,
  errorMessage: '',
  InputLabelProps: {},
  InputProps: {},
  inputProps: {},
  label: '',
  placeholder: '',
  newValue: '',
  onEnterPress: () => {},
  setFormData: () => {},
  type: 'text',
  variant: 'standard',
  multiline: false,
  rows: 1,
  parentName: '',
  childIndex: 0,
};

export default ControlledTextField;
