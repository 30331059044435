/** @format */

import { combineReducers } from 'redux';

import modalReducer from './modalSlice';
import snackbarReducer from './snackbarSlice';
import themeReducer from './themeSlice';

export default combineReducers({
  modal: modalReducer,
  snackbar: snackbarReducer,
  theme: themeReducer,
});
