/** @format */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Avatar, Chip, Paper, Grid, Typography, Tab, Tabs, Stack, useMediaQuery } from '@mui/material';
import { startCase } from 'lodash';

import { LabelValue, TabPanel, ViolationEvidence } from 'components';
import { formatDate, getFullName } from 'utils';

import ViolationLocation from './ViolationLocation';
import useStyles from './styles';

const ViolationInfo = ({ violation, violationImages, loading }) => {
  const minWidthReached = useMediaQuery('(min-width:480px)');
  const classes = useStyles({ minWidthReached });
  const [tabPanel, setTabPanel] = useState(0);

  const {
    colorHex = '',
    history = [],
    location,
    name = '',
    number = '',
    prefix = 0,
    nextDueDate = '',
    status = '',
    vehicle,
    violationStats = {},
    cameraData,
    scheduledForHearing = false,
  } = violation;

  const approvingOfficer = history.find((item) => item.action === 'Approved')?.user;

  const tabs = [
    {
      label: 'VIOLATION',
      content: (
        <Stack spacing={2}>
          {['date', 'time', 'speedLimit', 'measuredSpeed', 'overBy'].map((key) => (
            <LabelValue key={key} label={startCase(key)} value={violationStats[key]} />
          ))}
          <LabelValue
            label={minWidthReached ? 'Approving Officer' : 'Officer'}
            value={getFullName({ first: approvingOfficer?.firstName, last: approvingOfficer?.lastName })}
          />
        </Stack>
      ),
    },
    {
      label: 'VEHICLE',
      content: (
        <Stack spacing={2}>
          <LabelValue label='Plate Number' value={vehicle?.tagNumber} />
          <LabelValue label='Plate State' value={vehicle?.tagState} />
          <LabelValue label='Style' value={vehicle?.style} />
          <LabelValue label='Make' value={vehicle?.make} />
          <LabelValue label='Model' value={vehicle?.model} />
          <LabelValue label='Color' value={vehicle?.color} />
          {/* <LabelValue label='VIN' value={vehicle?.vin} /> hide VIN for now since it contains wrong data */}
        </Stack>
      ),
    },
  ];

  return (
    <Paper className={classes.root} elevation={3}>
      <Grid container direction='column' spacing={4}>
        <Grid container item spacing={2} alignItems='center' xl={12}>
          <Grid item xs='auto'>
            <Avatar className={classes.avatar} alt={name} src={loading ? '' : violationImages[0]} />
          </Grid>
          <Grid container item direction='column' spacing={1} xs>
            {loading ? (
              <Skeleton animation='wave' variant='rectangle' height={35} width='80%' />
            ) : (
              <Grid container item spacing={1} alignItems='center'>
                <Grid item>
                  <Typography variant={minWidthReached ? 'h2' : 'h4'}>
                    ({prefix}) {number}
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip label={status.toUpperCase()} size='small' sx={{ background: colorHex, borderRadius: 3 }} />
                </Grid>
                {scheduledForHearing && (
                  <Grid item>
                    <Chip label='HEARING SCHEDULED' size='small' color='secondary' sx={{ borderRadius: 3 }} />
                  </Grid>
                )}
              </Grid>
            )}

            <Grid container item spacing={1}>
              {nextDueDate ? (
                <>
                  <Grid item>
                    <Typography fontWeight={800}>Next Due Date:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography fontWeight={800} color='primary'>
                      {formatDate(nextDueDate).string.toUpperCase()}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <Grid item>
                  <Typography fontWeight={800}>No Active Due Date</Typography>
                </Grid>
              )}
            </Grid>
            <Grid container item spacing={1}>
              <Grid item>
                <Typography fontWeight={800}>License Plate:</Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight={800} color='primary'>
                  {vehicle?.tagNumber}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ViolationEvidence images={violationImages} loading={loading} />

        <Grid container item spacing={2}>
          <Grid item xs={12} lg={12} xl={6}>
            {loading ? (
              <Skeleton animation='wave' variant='rectangle' height={480} />
            ) : (
              <Stack spacing={3} className={classes.tabContainer}>
                <Tabs variant='fullWidth' onChange={(_, index) => setTabPanel(index)} value={tabPanel}>
                  {tabs.map((tab) => (
                    <Tab disableRipple icon={tab.icon} key={tab.label} label={tab.label} />
                  ))}
                </Tabs>
                {tabs.map((tab, index) => (
                  <TabPanel index={index} key={tab.label} value={tabPanel}>
                    {tab.content}
                  </TabPanel>
                ))}
              </Stack>
            )}
          </Grid>

          <Grid item xs={12} lg={12} xl={6}>
            {location?.gps && !loading ? (
              <ViolationLocation gps={location.gps} loading={loading.violation} cameraData={cameraData} />
            ) : (
              <Skeleton animation='wave' variant='rectangular' height={480} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

ViolationInfo.propTypes = {
  violation: PropTypes.shape({
    history: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.shape({
      gps: PropTypes.shape({}),
      locationNumber: PropTypes.string,
      streetName: PropTypes.string,
    }),
    vehicle: PropTypes.shape({
      tagNumber: PropTypes.string,
      tagState: PropTypes.string,
      style: PropTypes.string,
      make: PropTypes.string,
      model: PropTypes.string,
      color: PropTypes.string,
      vin: PropTypes.string,
    }),
    colorHex: PropTypes.string,
    name: PropTypes.string,
    number: PropTypes.string,
    prefix: PropTypes.number,
    nextDueDate: PropTypes.string,
    violationStats: PropTypes.shape({}),
    status: PropTypes.string,
    cameraData: PropTypes.shape({}),
    scheduledForHearing: PropTypes.bool,
  }),
  violationImages: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
};

ViolationInfo.defaultProps = {
  violation: {},
  violationImages: [],
  loading: false,
};

export default ViolationInfo;
