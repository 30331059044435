/** @format */

const agencies = [
  {
    id: 16,
    name: 'Brice, OH',
    city: 'Brice',
    state: 'OH',
    relatedMaypopAgencyName: '16 - BRICE',
  },
  {
    id: 17,
    name: 'Girard, OH',
    city: 'Girard',
    state: 'OH',
    relatedMaypopAgencyName: '17 - Girard',
  },
  {
    id: 18,
    name: 'Temple, GA',
    city: 'Temple',
    state: 'GA',
    relatedMaypopAgencyName: '18 - TEMPLE',
  },
  {
    id: 19,
    name: 'South Fulton, GA',
    city: 'South Fulton',
    state: 'GA',
    relatedMaypopAgencyName: '19 - South Fulton',
  },
  {
    id: 20,
    name: 'Ringgold, GA',
    city: 'Ringgold',
    state: 'GA',
    relatedMaypopAgencyName: '20 - RINGGOLD',
  },
];

export default agencies;
