/** @format */

import { makeStyles } from '@mui/styles';

export default makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  alert: { filter: 'unset' },
  content: {
    width: '100%',
    maxWidth: 2250,
    margin: '0px auto',
  },
});
