/** @format */

import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  root: { padding: ({ minWidthReached }) => theme.spacing(minWidthReached ? 4 : 2) },
  avatar: {
    height: ({ minWidthReached }) => (minWidthReached ? 80 : 64),
    width: ({ minWidthReached }) => (minWidthReached ? 80 : 64),
    fontSize: 40,
    color: theme.palette.avatar.text,
    background: theme.palette.avatar.background,
  },
}));
