/** @format */

import React from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, Modal as MuiModal, Paper } from '@mui/material';

import useModalStyles from './styles';

const Modal = () => {
  const classes = useModalStyles();
  const modal = useSelector((state) => state.modal);

  return (
    <MuiModal
      className={classes.root}
      open={!!modal.open}
      onBackdropClick={modal.onBackdropClick}
      BackdropComponent={Backdrop}
      BackdropProps={{ className: classes.backdrop }}>
      <Paper className={classes.content}>{modal.open}</Paper>
    </MuiModal>
  );
};

export default Modal;
