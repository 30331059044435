/** @format */

import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  root: { padding: ({ minWidthReached }) => theme.spacing(minWidthReached ? 4 : 2) },
  avatar: {
    height: ({ minWidthReached }) => (minWidthReached ? 80 : 64),
    width: ({ minWidthReached }) => (minWidthReached ? 80 : 64),
  },
  headerBtn: { [theme.breakpoints.down('lg')]: { width: '100%' } },
  tabContainer: {
    border: `1px solid ${theme.palette.ui.border}`,
    borderRadius: 6,
    height: '100%',
    marginBottom: theme.spacing(3),
    '& .MuiTab-root': { borderBottom: `1px solid ${theme.palette.ui.border}` },
  },
}));

export const useViolationLocationStyles = makeStyles((theme) => ({
  cardContainer: {
    filter: 'unset',
    border: `1px solid ${theme.palette.ui.border}`,
  },
  mapContainer: { height: 300 },
}));
