/** @format */

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from '.';

export default configureStore({
  middleware: getDefaultMiddleware({
    // Turn off serializable check for redux
    // flyout/setFlyout, modal/setModal, and modal/initializeModal are the actions that pass in a non-serializable value i.e. Symbol(react.element)
    // https://redux.js.org/faq/actions#why-should-type-be-a-string-or-at-least-serializable-why-should-my-action-types-be-constants
    serializableCheck: false,
  }),
  reducer: rootReducer,
});
