/** @format */

import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({ root: { height: '100%' } });

const Map = ({ mapRef, map, children }) => {
  const classes = useStyles();

  return (
    <>
      <div id='google-map' ref={mapRef} className={classes.root} />
      {cloneElement(children, { map })}
    </>
  );
};

Map.propTypes = {
  children: PropTypes.element,
  mapRef: PropTypes.shape({}),
  map: PropTypes.shape({}),
};

Map.defaultProps = {
  children: null,
  mapRef: null,
  map: null,
};

export default Map;
