/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';

const AsyncButton = ({ fullWidth, loading, loadingText, size, variant, ...props }) => {
  if (loading) {
    return (
      <Button
        disabled
        fullWidth={fullWidth}
        variant={variant}
        startIcon={<CircularProgress color='inherit' size={size === 'small' ? 16 : 20} />}
        size={size}
        sx={{ height: '100%' }}>
        {loadingText}
      </Button>
    );
  }
  return <Button {...props} fullWidth={fullWidth} size={size} variant={variant} />;
};

AsyncButton.propTypes = {
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
};

AsyncButton.defaultProps = {
  fullWidth: false,
  loading: false,
  loadingText: 'Loading...',
  size: 'medium',
  variant: 'contained',
};

export default AsyncButton;
