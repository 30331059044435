/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { kebabCase } from 'lodash';

const ControlledAutocomplete = ({
  control,
  defaultValue,
  disabled,
  error,
  errorMessage,
  getOptionLabel,
  handleChange,
  label,
  loading,
  loadingText,
  name,
  options,
  isOptionEqualToValue,
  multiple,
  variant,
  setFormData,
  parentName,
  childIndex,
  renderOption,
  renderTags,
  InputProps,
  ...rest
}) => (
  <Controller
    {...rest}
    control={control}
    defaultValue={defaultValue}
    name={name}
    render={(props) => (
      <Autocomplete
        {...props}
        multiple={multiple}
        isOptionEqualToValue={isOptionEqualToValue}
        autoComplete
        blurOnSelect
        disabled={disabled}
        fullWidth
        getOptionLabel={getOptionLabel}
        id={kebabCase(name)}
        loading={loading}
        loadingText={loadingText}
        onChange={(_, value) => {
          props.onChange(value);
          handleChange(value);
          setFormData((prev) =>
            parentName && childIndex
              ? {
                  ...prev,
                  [parentName]: {
                    ...prev[parentName],
                    [childIndex]: { ...prev[parentName][childIndex], [name]: value },
                  },
                }
              : { ...prev, [name]: value },
          );
        }}
        openOnFocus
        options={options}
        renderOption={renderOption}
        renderTags={renderTags}
        renderInput={(params) => (
          <form autoComplete='new-password'>
            <TextField
              {...params}
              error={error}
              helperText={errorMessage}
              inputRef={props.ref}
              label={label}
              InputProps={{
                ...params.InputProps,
                ...InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color='primary' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              variant={variant}
            />
          </form>
        )}
      />
    )}
  />
);

ControlledAutocomplete.propTypes = {
  isOptionEqualToValue: PropTypes.func,
  control: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  getOptionLabel: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  setFormData: PropTypes.func,
  ref: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.instanceOf(null)]),
  multiple: PropTypes.bool,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  parentName: PropTypes.string,
  childIndex: PropTypes.number,
  renderOption: PropTypes.func,
  renderTags: PropTypes.func,
  InputProps: PropTypes.shape({}),
};

ControlledAutocomplete.defaultProps = {
  isOptionEqualToValue: (option, value) => option === value,
  disabled: false,
  defaultValue: {},
  error: false,
  errorMessage: '',
  handleChange: () => {},
  loading: false,
  loadingText: 'Loading...',
  options: [],
  onChange: () => {},
  setFormData: () => {},
  ref: null,
  multiple: false,
  variant: 'standard',
  parentName: '',
  childIndex: 0,
  renderOption: undefined,
  renderTags: undefined,
  InputProps: {},
};

export default ControlledAutocomplete;
