/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { useVideoModalStyles } from './styles';

const VideoModal = ({ image, src }) => {
  const classes = useVideoModalStyles();

  return (
    <Grid className={classes.root}>
      <video autoPlay controls width='100%' height='auto' poster={image}>
        <track kind='captions' src='/no-captions.vtt' />
        <source src={src} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    </Grid>
  );
};

VideoModal.propTypes = {
  image: PropTypes.string,
  src: PropTypes.string,
};

VideoModal.defaultProps = {
  image: '',
  src: '',
};

export default VideoModal;
