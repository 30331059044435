/** @format */

const breakpoints = {
  values: {
    xs: 0,
    ss: 480,
    sm: 650,
    md: 950,
    lg: 1200,
    xl: 1440,
    xxl: 1520,
  },
};

export default breakpoints;
