/** @format */

import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdrop: { backgroundColor: 'rgba(27, 36, 48, .9)' },
  content: {
    borderRadius: theme.spacing(1),
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25), 0 0 5px 2px rgba(0, 0, 0, 0.16)',
    padding: theme.spacing(3),
    width: 'min-content',
  },
}));
