/** @format */

import { createGlobalStyle } from 'styled-components/macro';

export default createGlobalStyle`
  html,
  body,
  #root {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    margin: 0;
  }
  body {
    background: ${({ theme }) => theme.palette.background.default};
  }
`;
