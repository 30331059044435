/** @format */

const props = {
  MuiButtonBase: {
    disableRipple: false,
  },
  MuiCardHeader: {
    titleTypographyProps: { variant: 'h6' },
  },
};

export default props;
