/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box, Grid, Skeleton, Stack, Typography, IconButton } from '@mui/material';
import { PlayCircle } from '@mui/icons-material';
import { closeModal, openModal } from 'reducers/modalSlice';
import { VideoModal, Carousel } from 'components';

import useStyles from './styles';

const ViolationEvidence = ({ images, loading }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleOpenVideoModal = async (video) => {
    dispatch(openModal(<VideoModal image={images[0]} src={video} onBackdropClick={() => dispatch(closeModal())} />));
  };

  return (
    <Grid container item direction='column'>
      <Grid item>
        <Typography variant='h6'>Violation Evidence ({images.length} items)</Typography>
      </Grid>

      <Stack direction='row' spacing={2} className={classes.imageContainer}>
        {loading ? (
          [...Array(3)].map((_, index) => (
            <Skeleton key={index} animation='wave' variant='rectangular' height={200} width={200} />
          ))
        ) : (
          <>
            {images
              .filter((x) => x.includes('.webm') || x.includes('.mp4'))
              .map((image, index) => (
                <Box
                  id={index}
                  key={index}
                  className={classes.videoContainer}
                  onClick={() => handleOpenVideoModal(image)}>
                  <img src={images[0]} height={200} alt='violation video' />
                  <IconButton className={classes.videoButton} variant='contained' disableRipple>
                    <PlayCircle color='primary' />
                  </IconButton>
                </Box>
              ))}
            <Carousel>
              {images
                .filter((x) => !x.includes('.webm') && !x.includes('.avi') && !x.includes('.mp4'))
                .map((image, index) => (
                  <img alt={index} height={200} id={index} key={index} loading='lazy' src={image} srcSet={image} />
                ))}
            </Carousel>
          </>
        )}
      </Stack>
    </Grid>
  );
};

ViolationEvidence.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
};

ViolationEvidence.defaultProps = {
  images: [],
  loading: false,
};

export default ViolationEvidence;
