/** @format */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state, { payload: { content } }) => {
      state.open = content;
      state.onBackdropClick = content?.props?.onBackdropClick;
    },
    initializeModal: (state) => {
      state.open = false;
    },
  },
});

const { setModal, initializeModal } = modalSlice.actions;
export default modalSlice.reducer;

export const openModal = (content) => (dispatch) => {
  dispatch(setModal({ content }));
};

export const closeModal = () => (dispatch) => {
  dispatch(initializeModal());
};
